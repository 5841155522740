import {Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core'
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms'

/*
  Inspired from:
  - https://github.com/angular/angular/blob/master/packages/forms/src/directives/default_value_accessor.ts
  - https://github.com/khashayar/ng-trim-value-accessor/blob/master/src/trim-value-accessor.ts
*/
@Directive({
  selector: '[bgoTrimValue]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrimValueAccessorDirective),
      multi: true,
    },
  ],
})
export class TrimValueAccessorDirective implements ControlValueAccessor {
  private onChange?: (value: unknown) => void
  private onTouched?: () => void

  @HostListener('input', ['$event.target.value'])
  ngOnChange = (value: unknown) => {
    if (this.onChange) {
      this.onChange(this.trim(value))
    }
  }

  @HostListener('blur', ['$event.target.value'])
  ngOnBlur = (value: unknown) => {
    this.writeValue(value)
    if (this.onTouched) {
      this.onTouched()
    }
  }

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
  ) {}

  registerOnChange(onChange: (sourceValue: string) => void): void {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched
  }

  writeValue(value?: unknown): void {
    this.setProperty('value', this.trim(value))
  }

  setDisabledState(isDisabled: boolean): void {
    this.setProperty('disabled', isDisabled)
  }

  private trim(value: unknown): unknown {
    if (typeof value === 'string') {
      return value.trim()
    } else {
      return value
    }
  }

  private setProperty(key: string, value: unknown): void {
    this._renderer.setProperty(this._elementRef.nativeElement, key, value)
  }
}
