import {Directive, ElementRef, HostListener, Input} from '@angular/core'
import {FormGroupDirective} from '@angular/forms'
import {DocumentService} from '../../../craft/utils/document.service'

@Directive({
  selector: '[bgoScrollToInvalidOnSubmit]',
})
export class ScrollToInvalidOnSubmitDirective {
  constructor(
    private elementRef: ElementRef,
    private formGroup: FormGroupDirective,
    private documentService: DocumentService,
  ) {}

  @HostListener('ngSubmit') onSubmit(): void {
    if (this.formGroup.control.invalid) {
      this.scrollToFirstInvalidControl()
    }
  }

  @Input() scrollOffset = 0

  /** Additional selectors to exclude from the search for invalid controls */
  @Input() excludedSelectors: string[] = []

  private scrollToFirstInvalidControl() {
    // some additional selectors can be excluded
    const additionalExcludedSelectors = this.excludedSelectors.map(selector => `:not(${selector})`)

    // selects first invalid control from form, or alternative the form itself if no invalid control is found
    const firstInvalidControl: HTMLElement =
      this.elementRef.nativeElement.querySelector(`.ng-invalid:not(form)${additionalExcludedSelectors}`) ??
      this.elementRef.nativeElement

    if (firstInvalidControl) {
      this.documentService.scrollToElement(firstInvalidControl, {topOffset: -this.scrollOffset})
    }
  }
}
