import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {OpenNewTabDirective} from './open-new-tab/open-new-tab.directive'
import {ScrollToInvalidOnSubmitDirective} from './scroll-to-invalid-on-submit/scroll-to-invalid-on-submit.directive'
import {PrefixDirective} from './prefix/prefix.directive'
import {TrimValueAccessorDirective} from './trim-value-accessor/trim-value-accessor.directive'

@NgModule({
  declarations: [OpenNewTabDirective, ScrollToInvalidOnSubmitDirective, PrefixDirective, TrimValueAccessorDirective],
  imports: [CommonModule],
  exports: [OpenNewTabDirective, ScrollToInvalidOnSubmitDirective, PrefixDirective, TrimValueAccessorDirective],
})
export class DirectivesModule {}
