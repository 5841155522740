import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core'
import {NgControl} from '@angular/forms'

@Directive({
  selector: '[bgoPrefix]',
})
export class PrefixDirective implements OnInit {
  private static readonly keysWhiteList = ['ArrowLeft', 'ArrowRight', 'Home', 'End']

  @Input() bgoPrefix!: string
  @Input() alwaysVisible = false
  @Input() prefixPreventChange = true

  constructor(
    private readonly ngControl: NgControl,
    private readonly elementRef: ElementRef,
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault()
    event.stopPropagation()
  }

  @HostListener('focus', ['$event'])
  onFocus(): void {
    this.setValue()
  }

  ngOnInit(): void {
    if (this.alwaysVisible) {
      this.setValue()
    }

    if (this.prefixPreventChange) {
      this.elementRef.nativeElement.addEventListener('keydown', (e: KeyboardEvent) => {
        if (this.ngControl.control?.value?.startsWith(this.bgoPrefix)) {
          if (PrefixDirective.keysWhiteList.includes(e.key)) {
            return
          }

          if (e.key === 'Backspace' && this.elementRef.nativeElement.selectionStart === 4) {
            e.preventDefault()
            return
          }

          if (this.elementRef.nativeElement.selectionStart && this.elementRef.nativeElement.selectionStart >= 4) {
            return
          }

          e.preventDefault()
        }
      })
    }
  }

  setValue(): void {
    if (!this.ngControl.control?.value || this.ngControl.control.value.startsWith(this.bgoPrefix)) {
      if (!this.ngControl.control?.value) {
        this.ngControl.control?.setValue(this.bgoPrefix)
        setTimeout(() => this.elementRef.nativeElement.setSelectionRange(this.bgoPrefix.length, this.bgoPrefix.length))
      }
    }
  }
}
